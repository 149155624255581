import { createContext, SetStateAction, Dispatch} from "react";


interface IMenuContext {
    ledStatus: any; setLEDStatus: Dispatch<SetStateAction<any>>;
}
interface IMenuContext2 {
    buttonIdEstatus: string; setbuttonIdEstatus: Dispatch<SetStateAction<string>>;
}
export const ledModeContext = createContext<any>(false
);
export const idButtonContext= createContext<any>({
    buttonIdEstatus: '',
    setbuttonIdEstatus: (value:any) => value,
});
export const ledStatusModeDispatchContext = createContext<any>({
    themeStatus: false,
    setThemeStatus: (value:any) => value,
});
