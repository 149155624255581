import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import {
  idButtonContext,
  ledModeContext,
} from "../components/ProjectComponents/Modbus/ToggleButton/ledStatusContext";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {
  IonDetails,
  IonDataProps,
  IonDeviceDetailProps,
} from "../components/ProjectComponents";
import { StandardTemplate } from "../components/Templates";
import {
  Col,
  Row,
  LoadingScreen,
  Section,
  Headline,
  HeadlineContainer,
  ModbusRow,
} from "../components/GlobalComponents";
import { RedAnimation } from "../components/GlobalComponents/LoadingAnimation";
import * as M from "../components/ProjectComponents/Modbus";
import { DeviceProps, maybeParseJson } from "../data";

const client = new W3CWebSocket(`wss://nodered.vodafone.dev/ws/modbus`);

const ModbusPage = () => {
  const [view, setView] = useState<string>("overview");
  const [currentDevice, setCurrentDevice] = useState<string | null>(null);
  const [deviceDetail, setDeviceDetail] = useState<IonDeviceDetailProps>({
    data: [],
    latestData: {
      imei: "",
      data: {
        temp: 0,
        humidity: 0,
        batvol: 0,
      },
      timestamp: "",
    },
  });
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [overviewData, setOverviewData] = useState<DeviceProps[]>([]);
  const [loading, setLoading] = useState(true);

  const [temperatureValue, setTemperatureValue] = useState(0);
  const [isLEDRedOn, setisLEDRedOn] = useState(false);
  const [isLEDGreenOn, setisLEDGreenOn] = useState(false);
  const [isLEDOrangeOn, setisLEDOrangeOn] = useState(false);
  const [MeterCurrentValue, setMeterCurrentValue] = useState(0);
  const [MeterFrequencyValue, setMeterFrequencyValue] = useState(0);
  const [MeterEnergyValue, setMeterEnergyValue] = useState(0);
  const [MeterVoltageValue, setVoltageValue] = useState(0);
  const [Co2Value, setCo2Value] = useState(0);
  const [AirTemperatureValue, setAirTemperatureValue] = useState(0);
  const [AirHumidityValue, setAirHumidityValue] = useState(0);
  const [isRelayOn, setisRelayOn] = useState(false);
  const [ledStatus, setLEDStatus] = useState(false);
  const { buttonIdEstatus, setbuttonIdEstatus } = useContext(idButtonContext);
  const [relay_status, setRelayStatus] = useState(false);
  const [green_status, setGreenStatus] = useState(false);
  const [yellow_status, setYellowStatus] = useState(false);
  const [red_status, setRedStatus] = useState(false);
  const [autoModeStatus, setModeStatus] = useState(true);
  const [useTimes, setUseTimes] = useState(1);
  /**
   * useEffect is a React Hook that lets you synchronize a component with an external system.
   * synchronizes the websocket information with the ModBus Components
   */
  useEffect(() => {
    const myTimeout = setTimeout(myGreeting, 5000);
    function myGreeting() {
      //setThemeStatus(true);
      //console.log('estatus: '+themeStatus)
      setbuttonIdEstatus("greenled");
    }
    setLoading(false);
    /**
     * If no connection can be established we trow an alert
     */

    client.onclose = () => {
      alert("Backend connection lost.");
    };
    //connection was established and we recieve a message from the socket
    client.onmessage = (message) => {
      setLoading(false);
      /**
       * @param data to parse the data
       */
      const data = maybeParseJson(message.data);
      console.log(data);
      switchOnOff(data.modbusDeviceAddress, data.modbusRegisterStartingAddress);
      function switchOnOff(
        modbusDeviceAddress: any,
        modbusRegisterStartingAddress: any
      ) {
        let get_values;
        console.log(data.value);
        switch (modbusDeviceAddress) {
          /**n
           * Case 10 defines the Led Switching Color, ON Off
           */
          case 10:
            get_values =
              modbusRegisterStartingAddress === 16
                ? LEDSwitch(data.value, "green")
                : false;
            get_values =
              modbusRegisterStartingAddress === 32
                ? LEDSwitch(data.value, "orange")
                : false;
            get_values =
              modbusRegisterStartingAddress === 48
                ? LEDSwitch(data.value, "red")
                : false;
            break;
          /**
           * Case 1 Defines the setting of values of Meter Frequency, Voltage, Meter Current Value
           */
          case 1:
            console.log("voltage");
            get_values =
              modbusRegisterStartingAddress === 304
                ? setMeterFrequencyValue(data.value)
                : false;
            get_values =
              modbusRegisterStartingAddress === 305
                ? setVoltageValue(data.value)
                : false;
            get_values =
              modbusRegisterStartingAddress === 313
                ? setMeterCurrentValue(data.value)
                : false;
            get_values =
              modbusRegisterStartingAddress === 40960
                ? setMeterEnergyValue(data.value)
                : false;
            break;
          /**
           * Case 30 defines setting the values of Co2, Air Temperature, Air Humidity
           */
          case 30:
            get_values =
              modbusRegisterStartingAddress === 1 ? setC02(data.value) : false;
            get_values =
              modbusRegisterStartingAddress === 5
                ? setAirTemperatureValue(data.value)
                : false;
            get_values =
              modbusRegisterStartingAddress === 7
                ? setAirHumidityValue(data.value)
                : false;
            break;
        }
      }
      function LEDSwitch(value: any, color: string) {
        /**
         * Helper function to Unify the On Off Switching of the LED's
         */
        if (autoModeStatus == false || useTimes < 3) {
          let get_values;
          get_values =
            color == "green"
              ? value
                ? setGreenStatus(true)
                : setGreenStatus(false)
              : false;
          get_values =
            color == "orange"
              ? value
                ? setYellowStatus(true)
                : setYellowStatus(false)
              : false;
          get_values =
            color == "red"
              ? value
                ? setRedStatus(true)
                : setRedStatus(false)
              : false;
          setUseTimes(useTimes + 1);
        }
      }

      // Switch for the Power Relay
      if (
        data.modbusDeviceAddress === 20 &&
        data.modbusRegisterStartingAddress === 0
      ) {
        if (autoModeStatus == false || useTimes < 3) {
          if (data.value) {
            console.log("cosas que no deberian de pasar:");
            setRelayStatus(true);
          }
          if (data.value === 0) {
            setRelayStatus(false);
          }
          setUseTimes(useTimes + 1);
        }
      }

      //Set the Temperature
      if (
        data.modbusDeviceAddress === 13 &&
        data.modbusRegisterStartingAddress === 0
      ) {
        console.log("here we go");
        setTemperature(data.value);
      }
    };
  });
  function sendClientMessage(buttonId: string, state: boolean) {
    if (client.readyState === WebSocket.OPEN) {
      switch (buttonId) {
        case "Relaybutton":
          const message = {
            payload: JSON.stringify({
              comModuleId: "1cc168fa-69b9-4e31-bda8-40e28adabcae",
              deviceAddress: 20,
              startAddress: 0,
              functionCode: 6,
              quantity: null,
              dataType: "UINT16",
              correlationID: "single-write-123",
              value: state ? 1 : 0,
            }),
            topic: "mcc/write",
            qos: 0,
          };
          client.send(JSON.stringify(message));
          break;
        case "greenled":
          const message1 = {
            payload: JSON.stringify({
              comModuleId: "1cc168fa-69b9-4e31-bda8-40e28adabcae",
              deviceAddress: 10,
              startAddress: 16,
              functionCode: 6,
              quantity: null,
              dataType: "UINT16",
              correlationID: "single-write-123",
              value: state ? 1 : 0,
            }),
            topic: "mcc/write",
            qos: 0,
          };
          client.send(JSON.stringify(message1));
          break;
        case "orangeled":
          const message2 = {
            payload: JSON.stringify({
              comModuleId: "1cc168fa-69b9-4e31-bda8-40e28adabcae",
              deviceAddress: 10,
              startAddress: 32,
              functionCode: 6,
              quantity: null,
              dataType: "UINT16",
              correlationID: "single-write-123",
              value: state ? 1 : 0,
            }),
            topic: "mcc/write",
            qos: 0,
          };
          client.send(JSON.stringify(message2));
          break;
        case "redled":
          const message3 = {
            payload: JSON.stringify({
              comModuleId: "1cc168fa-69b9-4e31-bda8-40e28adabcae",
              deviceAddress: 10,
              startAddress: 48,
              functionCode: 6,
              quantity: null,
              dataType: "UINT16",
              correlationID: "single-write-123",
              value: state ? 1 : 0,
            }),
            topic: "mcc/write",
            qos: 0,
          };
          client.send(JSON.stringify(message3));
          break;
        default:
      }
    }
  }
  function setC02(value: any) {
    setCo2Value(value);
  }
  function setTemperature(value: any) {
    setTemperatureValue(value);
    if (autoModeStatus == false) {
      return;
    }
    if (value < 260) {
      const new_estatus = !relay_status
        ? sendClientMessage("Relaybutton", true)
        : false;
      sendClientMessage("greenled", false);
      setRelayStatus(true);
      setGreenStatus(false);
      if (value >= 250 && value < 260) {
        const new_estatus = !yellow_status
          ? sendClientMessage("orangeled", true)
          : false;
        setYellowStatus(true);
        const new_estatus2 = red_status
          ? sendClientMessage("redled", false)
          : false;
        setRedStatus(false);
      } else if (value < 250) {
        const new_estatus = yellow_status
          ? sendClientMessage("orangeled", false)
          : false;
        setYellowStatus(false);
        console.log(red_status);
        const new_estatus2 = !red_status
          ? sendClientMessage("redled", true)
          : false;
        setRedStatus(true);
      }
    } else {
      sendClientMessage("Relaybutton", false);
      setRelayStatus(false);
      if (value >= 260 && value < 270) {
        const new_estatus2 = !green_status
          ? sendClientMessage("greenled", true)
          : false;
        setGreenStatus(true);
        const new_estatus = yellow_status
          ? sendClientMessage("orangeled", false)
          : false;
        setYellowStatus(false);
        const new_estatus3 = red_status
          ? sendClientMessage("redled", false)
          : false;
        setRedStatus(false);
        setRelayStatus(false);
      } else if (value >= 270 && value < 280) {
        sendClientMessage("orangeled", true);
        setYellowStatus(true);
        const new_estatus2 = red_status
          ? sendClientMessage("redled", false)
          : false;
        setRedStatus(false);
        const new_estatus3 = green_status
          ? sendClientMessage("greenled", false)
          : false;
        setGreenStatus(false);
      } else if (value >= 280) {
        sendClientMessage("redled", true);
        setRedStatus(true);
        sendClientMessage("orangeled", false);
        setYellowStatus(false);
        sendClientMessage("greenled", false);
        setGreenStatus(false);
      }
    }
  }
  function changemodestatus() {
    setModeStatus(!autoModeStatus);
    console.log("en dashboard: " + autoModeStatus);
  }
  function changeLEDStatus(buttonId: string) {
    if (autoModeStatus == false) {
      switch (buttonId) {
        case "Relaybutton":
          setRelayStatus(!relay_status);
          break;
        case "greenled":
          setGreenStatus(!green_status);
          break;
        case "orangeled":
          setYellowStatus(!yellow_status);
          break;
        case "redled":
          setRedStatus(!red_status);
          break;
        default:
      }
    }
  }
  const changeView = (id?: string) => {
    setView(id ? "detail" : "overview");
    if (id) {
      setCurrentDevice(id);
    }
  };
  const CO2data = [
    {
      name: "Page A",
      CO2Raumfühler: 4000,
      TemperaturRaumfühler: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      CO2Raumfühler: 3000,
      TemperaturRaumfühler: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      CO2Raumfühler: 2000,
      TemperaturRaumfühler: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      CO2Raumfühler: 2780,
      TemperaturRaumfühler: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      CO2Raumfühler: 1890,
      TemperaturRaumfühler: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      CO2Raumfühler: 2390,
      TemperaturRaumfühler: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      CO2Raumfühler: 3490,
      TemperaturRaumfühler: 4300,
      amt: 2100,
    },
  ];

  return (
    <StandardTemplate>
      <Section>
        {view === "overview" && (
          <>
            <HeadlineContainer color={"monochrome500"}>
              <Row middle={"xs"} center={"xs"}>
                <Col>
                  <Headline
                    text={"Modbus Cloud Connect"}
                    as={"h2"}
                    styleAs={"h1"}
                  />
                </Col>
              </Row>
            </HeadlineContainer>
            <M.AutoContainer>
              <M.ToggleButton
                buttonId={"autoMode"}
                onClick={() => changemodestatus()}
                Status={autoModeStatus}
                autoMode={autoModeStatus}
              />
              <p>Auto Mode</p>
            </M.AutoContainer>
            <M.ModbusContent>
              <ModbusRow>
                <M.MeterBox
                  title="Socket Voltage"
                  Label="Voltage(V)"
                  voltage="yes"
                  MeterVoltage={MeterVoltageValue / 100}
                />
                <M.MeterBox
                  title="Socket Ampere"
                  Label="Ampere(A)"
                  current="yes"
                  MeterAmpere={MeterCurrentValue}
                />
                <M.MeterBox
                  title="Socket Hz"
                  Label="Hz"
                  frequency="yes"
                  MeterFrequency={MeterFrequencyValue / 100}
                />
                <M.MeterBox
                  title="Energy"
                  Label="Wh"
                  energy="yes"
                  MeterEnergy={MeterEnergyValue}
                />
              </ModbusRow>
              <ModbusRow>
                <M.BoxForValues
                  title="Relay Socket"
                  tooltip="before 9 days"
                  isRelayOn={relay_status}
                  Buttonid=""
                  autoModeStatus={autoModeStatus}
                />
                <M.BoxForValues
                  title="LED Green Status"
                  tooltip="before 9 days"
                  LEDGreenOn={green_status}
                  Buttonid=""
                  Green="yes"
                  autoModeStatus={autoModeStatus}
                />
                <M.BoxForValues
                  title="LED Yellow Status"
                  tooltip="before 9 days"
                  LEDOrangeOn={yellow_status}
                  Buttonid=""
                  Orange="yes"
                  autoModeStatus={autoModeStatus}
                />
                <M.BoxForValues
                  title="LED Red Status"
                  tooltip="before 9 days"
                  LEDRedOn={red_status}
                  Buttonid=""
                  Red="yes"
                  autoModeStatus={autoModeStatus}
                />
              </ModbusRow>
              <ModbusRow>
                <M.BoxForValues
                  togglebutton="Yes"
                  Buttonid="Relaybutton"
                  Status={relay_status}
                  autoModeStatus={autoModeStatus}
                  onClick={() => changeLEDStatus("Relaybutton")}
                />
                <M.BoxForValues
                  togglebutton="Yes"
                  Buttonid="greenled"
                  Status={green_status}
                  autoModeStatus={autoModeStatus}
                  onClick={() => changeLEDStatus("greenled")}
                />
                <M.BoxForValues
                  togglebutton="Yes"
                  Buttonid="orangeled"
                  Status={yellow_status}
                  autoModeStatus={autoModeStatus}
                  onClick={() => changeLEDStatus("orangeled")}
                />
                <M.BoxForValues
                  togglebutton="Yes"
                  Buttonid="redled"
                  Status={red_status}
                  autoModeStatus={autoModeStatus}
                  onClick={() => changeLEDStatus("redled")}
                />
              </ModbusRow>
              <ModbusRow>
                <M.MeterBox
                  title="CO2 Room Sensor"
                  Label="ppm"
                  isCo2="yes"
                  Co2={Co2Value}
                />
                <M.MeterBox
                  title="PT100 Sensor"
                  Label="°C"
                  Temperature="yes"
                  TemperatureValue={temperatureValue / 10}
                />
                <M.MeterBox
                  title="Temperatur Room Sensor"
                  Label="°C"
                  tooltip="before 1 minute"
                  isAirTemperature="yes"
                  AirTemperature={AirTemperatureValue / 10}
                />
                <M.MeterBox
                  title="RH Room Sensor"
                  Label="%rh"
                  tooltip="before 1 minute"
                  isHumidity="yes"
                  Humidity={AirHumidityValue / 10}
                />
              </ModbusRow>
              <M.RoomSensorchart data={CO2data} />
              <M.SensorDataChart data={CO2data} />
            </M.ModbusContent>
          </>
        )}
        {view === "detail" && (
          <IonDetails
            deviceDetail={deviceDetail}
            changeView={() => changeView()}
          />
        )}
      </Section>
      <LoadingScreen show={loading} animation={RedAnimation} />
    </StandardTemplate>
  );
};

export default ModbusPage;
